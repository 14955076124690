@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  margin-bottom: rem(26);
}

.toggler {
  align-items: center;
  border-bottom: 1px solid $grey-200;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding-bottom: rem(10);

  > h2 {
    font-size: rem(15);
    font-weight: 600;
    line-height: rem(18);
  }
}
