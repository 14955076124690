@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  margin-bottom: rem(40);

  > h4 {
    font-size: rem(18);
    margin-bottom: rem(22);
  }
}
