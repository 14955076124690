@import "~variables.scss";
@import "~tools.scss";

.carouselBook {
  width: 100%;
  padding: 0 40px;
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  height: 400px;
}

.consultationsWraper {
  padding: 0px;
  height: 100%;
  padding: 10px 0;

  > div {
    border: solid 1px #e0e0e0;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.consultationsImage {
  width: 100%;
  height: 200px;
  margin-bottom: 5px
}

.componentName {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0px;
}

.name {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  margin-top: 5px;
  font-size: 18px;
  line-height: 18px;
  color: #444444;
  flex-grow: 1;
}

span:hover{
  cursor: pointer !important;
}

.downloadWrapper{
  height: 50px;
}

.file {
  float: right;
  width: 20px;
}

.line {
  margin-bottom: 10px
}

.button {
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.modalButton {
  text-align: center;
}

.carouselDivider {
  margin-bottom: 5px;
}
