@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  color: $grey-600;
  font-size: rem(13);
  line-height: rem(18);

  > p {
    margin-bottom: rem(2);
  }

  > h1 {
    color: black;
    font-size: rem(21);
    line-height: rem(28);
  }
}

.image {
  margin-bottom: rem(12);
  object-fit: cover;
  width: rem(226);
  height: rem(114);
}
