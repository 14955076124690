@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  align-items: center;
  border-bottom: 1px solid $grey-200;
  color: $grey-600;
  display: flex;
  font-size: rem(13);
  justify-content: flex-start;
  line-height: rem(18);
  padding-top: rem(16);
  padding-bottom: rem(16);

  &:last-child {
    border-bottom: 0;
  }
}

.btnEdit {
  margin-left: auto;
}
