@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 24rem;
  text-align: center;
}
