@import "~variables.scss";
@import "~tools.scss";

.buttonText{
  margin-left: 15px;
}

.header {
  composes: container from global;
  font-size: 1.125rem;
  padding-top: 1.4375rem;
  padding-bottom: 1.25rem;
}

.wrapperText{

  margin-top: 50px;
}

.image {
  width: 100%;
  height: 100%;

  > h1 {
    font-size: 2.5rem;
    line-height: 1;
    max-width: 50%;
  }
}

.slogan {
  composes: container from global;
  font-size: 1.3125rem;
  font-weight: 600;
  padding-top: 5rem;
  padding-bottom: 5rem;
  text-align: center;

  p {
    margin-bottom: 0;
  }
}

.wrapperBox{
  border: 2px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0.25rem;
  padding: 5%;
  box-shadow: 2px 2px 2px #F5F5F8;
}

.lastText{
  text-align: center;
}

.lawListFirst{
  padding-left: 5%;
  padding-right: 5%;
  list-style: decimal;
}

.lawListSecond{
  padding-left: 5%;
  padding-right: 5%;
  list-style: upper-roman;
}

.lawListThird{
  padding-left: 10%;
  padding-right: 10%;
  list-style: decimal;
}

.educationList{
  margin-top: 20px;
  list-style: none;
}

.listTitle{
  margin-top: 40px;
}

.list {
  display: flex;
  flex-flow: row nowrap;
  list-style: none;
  justify-content: space-around;
  margin-bottom: 0;

  > li {
    padding: 5.5rem 2.8rem;
    text-align: center;
    flex-grow: 1;
    flex-basis: 33%;

    > a {
      > img {
        margin-bottom: 1.3125rem;
      }

      > h2 {
        font-size: 1.3125rem;
        margin-bottom: 0.75rem;
        color: black;
      }
    }

    > p {
      margin-bottom: 1.5rem;
    }
  }
}

li.sustainableDevGoals > p {
  color: $grey-600;
}

li.knowledgeMatrix {
  background: $grey-700;
  color: white;

  > a,
  > a > h2 {
    color: white;
  }
}

li.learningObjectives {
  background: #0bdb9f;
}

.doubleLineHeight {
  line-height: 2rem;
  text-align: justify;
}

.credit {
  font-size: 22px;
}

.contribuitorsList {
  padding-left: 10px;
  padding-right: 10px;
}

.collapseButton {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0.25rem;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  justify-content: center;
  width: 100%;
  height: 70px;
  padding: 10px;
}

.btn {
  composes: btn from global;

  .knowledgeMatrix & {
    border-color: rgba(255, 255, 255, 0.2);
  }
}

@media only screen and (max-width: 768px) {
  .header {
    font-size: 1rem;
    padding-bottom: 0;
  }

  .image {
    margin-bottom: 1.25rem;

    > h1 {
      font-size: 2.125rem;
      max-width: none;
    }
  }

  .slogan {
    font-size: 1.125rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .list {
    flex-direction: column;

    > li {
      padding: 2.75rem 1rem;
      width: 100%;

      > p {
        margin-bottom: 1rem;
      }
    }
  }
}
