@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  @include pill;
  border: 1px solid $grey-200;

  &:hover {
    border-color: $grey-600;
  }

  &.isActive {
    border-color: transparent;
    color: white;
  }
}
