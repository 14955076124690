@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  color: $grey-800;
  font-size: rem(15);
  font-weight: 600;
  line-height: rem(18);
  margin-bottom: rem(36);

  > p {
    margin-bottom: rem(10);
  }
}

.stars {
  > button {
    margin-right: rem(14);
  }
}
