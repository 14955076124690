@import "~variables.scss";
@import "~tools.scss";

.buttons {
  display: flex;
  padding: rem(10) rem(16);

  > a:first-child {
    margin-right: rem(10);
  }
}

.btnClose {
  margin-left: auto;
}

.info {
  color: $grey-600;
  font-size: rem(13);
  line-height: rem(18);
  padding: rem(10) rem(16) rem(30) rem(16);

  > h2 {
    color: black;
    font-size: rem(21);
    line-height: rem(36);
    margin-bottom: rem(4);
  }

  > p {
    margin-bottom: rem(6);
  }
}

.years {
  display: flex;
  margin-left: rem(-1);
}

@include mq("tablet-portrait") {
  .header {
    composes: container from global;
    align-items: flex-start;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .btnClose {
    display: none;
  }

  .info {
    padding-left: 0;
    padding-right: 0;
  }
}
