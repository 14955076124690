@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  flex-grow: 10;
  padding: rem(15);
}

.btnClose {
  position: absolute;
  right: rem(15);
  top: rem(21);
}

.header {
  align-items: center;
  color: $grey-600;
  display: flex;
  flex-direction: column;
  font-size: rem(15);
  font-weight: 600;
  justify-content: center;
  line-height: rem(22);
  margin-top: rem(43);
  margin-bottom: rem(50);

  > img {
    margin-bottom: rem(21);
  }

  > h1 {
    color: black;
    font-size: rem(18);
    font-weight: inherit;
    margin: 0;
    margin-bottom: rem(16);
  }

  > p {
    margin: 0;
  }
}

.spacer {
  margin-bottom: rem(40);
}

.btnForgot {
  color: $grey-600;
  display: block;
  font-size: rem(13);
  line-height: rem(24);
  margin: 0 auto;
  text-align: center;
  text-decoration: underline;
}

@include mq("tablet-portrait") {
  .wrapper {
    padding: rem(18) rem(95);
  }

  .header {
    margin-top: rem(13);
    margin-bottom: rem(20);
  }

  .spacer {
    margin-bottom: rem(30);
  }
}
