@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  position: relative;
}

.letter,
.image {
  border-radius: 50%;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.image {
  object-fit: cover;
}

.letter {
  align-items: center;
  background-color: $color-turquoise;
  color: white;
  display: flex;
  font-size: rem(34);
  font-weight: 600;
  justify-content: center;
}
