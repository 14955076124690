@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  padding-top: rem(80);
  padding-bottom: rem(40);

  &:nth-child(even) {
    background-color: $color-lightest-green;
  }
}

.container {
  composes: container from global;

  font-size: rem(16);
  line-height: rem(21);

  h3 {
    color: $color-green;
    font-size: rem(34);
    line-height: rem(36);
    margin-bottom: rem(36);
    text-align: center;
  }

  p {
    margin-bottom: 0;
  }
}

.image {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: rem(50);
  position: relative;
  width: rem(170);

  > img {
    width: 100%;
  }

  > div {
    align-items: center;
    background-color: white;
    border-radius: rem(54);
    box-shadow: 0 rem(2) rem(20) rgba(0, 0, 0, 0.1);
    color: $color-green;
    display: flex;
    font-size: rem(34);
    font-weight: 600;
    justify-content: center;
    position: absolute;
    right: rem(10);
    top: rem(116);
    width: rem(54);
    height: rem(54);
  }
}

@include mq("tablet-portrait") {
  .wrapper {
    padding-top: rem(60);
    padding-bottom: rem(74);
  }

  .container {
    position: relative;

    h3 {
      margin-bottom: rem(20);
      text-align: left;
    }
  }

  .image {
    margin-bottom: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .wrapper:nth-child(even) {
    .container {
      h3,
      p {
        margin-left: rem(285);
      }
    }

    .image {
      left: 0;
    }
  }

  .wrapper:nth-child(odd) {
    .container {
      h3,
      p {
        margin-right: rem(285);
      }
    }

    .image {
      right: 0;
    }
  }
}
