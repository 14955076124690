@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;

  img {
    width: 3.375rem;
    height: 3.375rem;
  }
}
