@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 10;
}

.h1 {
  font-size: rem(40);
  margin-bottom: rem(20);
  margin-top: rem(27);
}

.h2 {
  font-weight: normal;
  font-size: rem(18);
  line-height: rem(22);
  margin-top: rem(12);
  margin-bottom: rem(2);
}

.list {
  background-color: $grey-100;
}

.results {
  composes: container from global;
  padding-top: rem(16);
  padding-bottom: rem(16);
}

.results > ul > li {
  position: relative;
}

.center {
  margin-bottom: rem(24);
  text-align: center;
}

.load {
  composes: btn from global;
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .h1 {
    font-size: rem(34);
    margin-bottom: rem(12);
  }
}
