@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  background: $grey-600;
  color: white;
  text-align: center;
}

.logos {
  align-items: center;
  border-bottom: 1px solid $grey-500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;

  > img {
    margin: 2rem;
    height: 6rem;
    width: auto;
  }

  @include mq("tablet-portrait") {
    flex-direction: row;
    max-height: 9rem;
  }
}

.license {
  composes: container from global;
  font-size: 0.75rem;
  line-height: 1.125rem;
  padding-top: 0.625rem;
  padding-bottom: 0.5rem;

  > a {
    display: inline-block;
    margin-bottom: 0.8125rem;
  }

  a {
    color: white;
    font-weight: bold;

    .license a:hover {
      color: white;
    }
  }
}

@media only print {
  .logos {
    display: none;
  }
}
