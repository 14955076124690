@import "~variables.scss";
@import "~tools.scss";

.title{
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 37px;
  color: #42474A;
}

.contentText{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #42474A;
  text-align: justify;
}

.segmentName{
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #42474A;
}
