@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  border: 1px solid $grey-200;
  border-bottom: 0;
  font-size: 1rem;
  padding: 1.5625rem 1.875rem;

  &:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  &:last-child {
    border-bottom: 1px solid $grey-200;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    padding: 1rem 1.25rem 1.125rem 1.25rem;
  }
}
