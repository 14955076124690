@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: rem(12) 0;
}

.title {
  > p {
    color: $grey-600;
    font-size: rem(15);
    font-weight: 600;
    line-height: rem(21);
    margin-bottom: rem(4);
  }

  > h1 {
    font-size: rem(24);
    line-height: rem(33);
    margin: 0;
  }
}

.btnSave {
  align-items: center;
  color: $grey-600;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > img {
    margin: 0;
    margin-bottom: rem(4);
  }
}

@include mq("tablet-landscape") {
  .wrapper {
    padding: rem(16) 0 rem(44) 0;
  }

  .title {
    margin-right: rem(32);

    > p {
      font-size: rem(18);
      line-height: rem(24);
    }

    > h1 {
      font-size: rem(40);
      line-height: rem(48);
    }
  }

  .btnSave {
    composes: btn from global;
    color: black;
    flex-direction: row;

    > img {
      margin: 0;
      margin-right: rem(18);
    }
  }
}
