@import "~variables.scss";
@import "~tools.scss";

.header {
  background-color: $color-lightest-green;
  padding-top: rem(46);

  h1 {
    margin-bottom: rem(24);
  }

  p {
    font-size: rem(18);
    font-weight: 600;
    line-height: rem(21);
    margin-bottom: rem(46);
    width: 50%;
  }
}

.header h1,
.challenges h2 {
  font-size: rem(34);
  letter-spacing: -1px;
  line-height: rem(36);
}

.headerContainer {
  composes: container from global;
  overflow: hidden;
}

.headerRow {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.headerCol1 {
  composes: offset-md-2 from global;
}

.headerCol2 {
  margin-right: rem(-70);
  margin-top: rem(-110);
  width: rem(270);
}

.whatItIs,
.axes,
.objectives {
  composes: container from global;
  text-align: center;
}

.whatItIs,
.axes,
.methodologies,
.objectives {
  h2 {
    font-size: rem(28);
    letter-spacing: -0.5px;
    line-height: rem(28);
  }
}

.whatItIs {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 rem(5) rem(20) rgba(0, 0, 0, 0.1);
  font-size: rem(16);
  line-height: rem(21);
  margin-top: rem(-42);
  margin-bottom: rem(60);
  margin-left: rem(15);
  margin-right: rem(15);
  padding-top: rem(42);
  padding-bottom: rem(32);
  z-index: 1;

  h2 {
    margin-bottom: rem(22);
  }
}

.axes {
  margin-bottom: rem(54);

  h2 {
    margin-bottom: rem(42);
  }

  img {
    margin-bottom: rem(10);
  }

  h3 {
    color: $grey-800;
    font-size: rem(18);
    line-height: rem(24);
    margin-bottom: rem(10);
  }

  p {
    color: $grey-600;
    font-size: rem(16);
    line-height: rem(21);
  }
}

.methodologies {
  background-color: $color-green;
  color: white;
  font-size: rem(18);
  line-height: rem(24);
  padding-top: rem(64);
  padding-bottom: rem(32);
  text-align: center;

  h2 {
    margin-bottom: rem(40);
  }

  p {
    margin-bottom: rem(20);
  }
}

.objectives {
  padding-top: rem(42);
  padding-bottom: rem(60);

  h2 {
    margin-bottom: rem(12);
  }

  p {
    margin-bottom: rem(14);
  }
}

.challenges {
  background-color: $grey-100;
  padding-top: rem(44);
  padding-bottom: rem(62);

  h2 {
    margin-bottom: rem(36);
  }
}

.center {
  text-align: center;
}

.btnChallenges {
  composes: btn from global;
  composes: btnBig from global;
}

@include mq("mobile-large") {
  .whatItIs {
    margin-left: auto;
    margin-right: auto;
  }
}

@include mq("tablet-portrait") {
  .header {
    height: rem(475);
    padding-top: rem(40);

    h1 {
      margin-top: rem(84); 
    }

    p {
      width: auto;
    }
  }

  .headerContainer {
    height: 100%;
  }

  .headerRow {
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    margin-right: -15px;
    margin-left: -15px;
  }

  .headerCol1 {
    align-self: flex-start;
    margin-right: rem(34);
    width: rem(330);
  }

  .headerCol2 {
    flex: 1;
    margin-right: 0;
    margin-top: 0;
    max-width: rem(380);
  }

  .methodologies {
    background-color: transparent;
    position: relative;

    &::before {
      content: '';
      display: block;
      background-color: $color-green;
      width: 100%;
      height: 100%;
      max-height: rem(600);
      position: absolute;
      top: 0;
      z-index: -1;
    }
  }

  .objectives {
    padding-top: rem(30);
    
    h2 {
      margin-bottom: rem(16);
    }

    p {
      margin-bottom: rem(18);
    }
  }

  .challenges {
    h2 {
      margin-bottom: rem(42);
    }
  }
}
