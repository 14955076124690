@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  @include pill;
  background-color: $grey-100;
  display: inline-block;

  &.isColored {
    color: white;
  }

  &.isOutlined {
    background-color: transparent;
    border: 1px solid $grey-200;
  }

  &.isSmall {
    font-size: rem(12);
    margin-right: rem(6);
    margin-bottom: rem(6);
    padding: rem(3) rem(12);
  }
}
