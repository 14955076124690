@import "~variables.scss";
@import "~tools.scss";

.header {
  composes: container from global;
  padding: rem(16);
}

.rowName {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: rem(23);
}

.photoAndName {
  align-items: center;
  display: flex;

  > .name {
    margin-left: rem(15);
  }

  > h2 {
    font-size: rem(20);
    line-height: rem(24);
  }

  a {
    color: $grey-600;
    display: none;
    font-size: rem(15);
    font-weight: bold;
    text-decoration: underline;
  }
}

.rowNumbers {
  align-items: flex-start;
  color: $grey-600;
  display: flex;
  font-size: rem(11);
  justify-content: space-between;
  line-height: rem(18);
  margin-bottom: rem(22);

  > div {
    border-left: 1px solid $grey-200;
    padding-left: rem(24);
    padding-right: rem(24);
    width: 33%;

    &:first-child {
      border-left: 0;
      padding-left: rem(10);
    }

    > em {
      color: black;
      display: block;
      font-size: rem(18);
      font-style: normal;
    }
  }
}

@include mq("tablet-portrait") {
  .header {
    align-items: center;
    display: flex;
    flex-direction: row;

    > a {
      width: auto !important;
    }
  }

  .photoAndName {
    a {
      display: block;
    }
  }

  .rowName,
  .rowNumbers {
    margin-bottom: 0;
  }

  .rowName {
    > a {
      display: none;
    }
  }

  .rowNumbers {
    margin-left: auto;

    > div {
      width: auto;
    }
  }
}
