@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  align-items: center;
  border-bottom: 1px solid $grey-200;
  color: $grey-800;
  display: flex;
  height: rem(72);
  justify-content: space-between;
  margin-right: 0;
  padding: 0.5rem 0.9375rem;
  position: relative;
  z-index: 60;

  :global(.headroom) & {
    top: 0;
    left: 0;
    right: 0;
  }

  :global(.headroom--unfixed) & {
    position: relative;
    transform: translateY(0);
  }

  :global(.headroom--scrolled) & {
    background-color: white;
    border-bottom: 1px solid $grey-200;
    z-index: 110;
    transition: transform 200ms ease-in-out;
  }

  :global(.headroom--unpinned) & {
    position: fixed;
    transform: translateY(-100%);
  }

  :global(.headroom--pinned) & {
    position: fixed !important;
    transform: translateY(0%);
  }

  :global(.isHome) & {
    border-bottom: 0;
    position: absolute;
  }
}

.logo {
  align-items: center;
  color: $grey-800;
  display: flex;

  h1 {
    font-size: 1.125rem;
    margin-bottom: 0;
    max-width: 12rem;
  }
}

.logoImage {
  background-image: url(images/logo.svg);
  margin-right: 0.8125rem;
  width: rem(33);
  height: rem(55);

  :global(.isHome) & {
    background-image: url(images/logoWhite.svg);
  }

  :global(.headroom--pinned) & {
    background-image: url(images/logo.svg);
  }
}

.menu {
  display: none;
}

.mobile {
  align-items: center;
  display: flex;
}

.toggler {
  background-image: url(./images/menu.svg);
  margin-left: rem(20);
  width: rem(40);
  height: rem(21);

  :global(.isHome) & {
    background-image: url(./images/menuWhite.svg);
  }

  :global(.headroom--pinned) & {
    background-image: url(./images/menu.svg);
  }
}

.avatar {
  align-items: center;
  display: flex;

  > img {
    display: none;
    margin-left: rem(8);
    opacity: 0.7;
    width: rem(12);
  }
}

:global(.isHome) {
  .wrapper,
  .menu > a,
  .menu > button,
  .logo {
    color: white;
  }

  :global(.headroom-wrapper) {
    height: 0 !important;
  }
}

:global(.headroom--pinned) {
  .wrapper,
  .menu > a,
  .menu > button,
  .logo {
    color: $grey-800;
  }
}

@include mq("tablet-landscape") {
  .wrapper {
    padding: 0.5rem 2.5rem;
  }

  .menu {
    align-items: center;
    display: flex;

    > a,
    > button {
      color: black;
      font-size: rem(15);
      font-weight: bold;
      padding: 0 rem(15);
      text-transform: uppercase;
    }

    > div {
      margin: 0 rem(15);
    }
  }

  .mobile {
    display: none;
  }

  .avatar {
    > img {
      display: inline;
    }
  }
}

@media only print {
  .wrapper {
    display: none;
  }
}
