@import "~variables.scss";
@import "~tools.scss";

.arrows {
  composes: container from global;
  display: flex;
  justify-content: space-between;
}

.next,
.prev {
  color: $grey-600;
  font-size: 0.9375rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;

  > img {
    border: 1px solid $grey-200;
    border-radius: 3rem;
    line-height: 3rem;
    padding: 0.9375rem;
    width: 3rem;
    height: 3rem;
    text-align: center;
  }
}

.next > img {
  margin-left: 0.4375rem;
}

.prev > img {
  margin-right: 0.4375rem;
}

.footer {
  composes: container from global;
  text-align: center;
}

.back {
  composes: btn from global;
  display: inline-block;
  margin: 1.875rem auto;
}

@include mq("tablet-portrait") {
  .next,
  .prev {
    font-size: 1.125rem;
  }
}

@media only print {
  .arrows,
  .footer {
    display: none;
  }
}
