@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  align-items: center;
  background-color: $grey-200;
  border-radius: rem(4);
  color: white;
  display: flex;
  justify-content: center;
  font-size: rem(11);
  font-weight: bold;
  line-height: rem(18);
  margin-right: rem(2);
  margin-bottom: rem(2);
  width: rem(34);
  height: rem(34);
}

.isBordered {
  border: 3px solid $grey-600;
}
