@import "~variables.scss";
@import "~tools.scss";

.header,
.intro,
.sequences,
.curriculum {
  text-align: center;
}

.header {
  align-items: center;
  background-image: url(./home.jpg);
  background-position: 50% 50%;
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  height: rem(520);
  justify-content: center;
  padding-top: rem(70);

  p {
    font-size: rem(21);
    line-height: rem(24);
    margin-bottom: rem(34);
  }
}

.intro,
.curriculum {
  composes: container from global;
}

.intro {
  font-weight: 600;
  padding-top: 3.5rem;
  padding-bottom: 3.75rem;

  p {
    font-size: 1.3125rem;
    margin-bottom: 3.75rem;
  }

  ul {
    font-size: 1.125rem;
    margin-bottom: 2.375rem;
  }

  img {
    margin-bottom: 1.125rem;
  }
}

.intro ul,
.sequences ul,
.curriculum ul {
  list-style: none;
}

.sequences h2,
.curriculum h2 {
  font-size: 1.875rem;
}

.sequences {
  background: $grey-100;
  padding-top: 3.125rem;
  padding-bottom: 3.75rem;

  h2 {
    margin-bottom: 2.5rem;
  }
}

.curriculum {
  padding-top: 4rem;
  padding-bottom: 3.75rem;

  h2 {
    margin-bottom: 3.75rem;
  }

  ul {
    color: $grey-800;
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 3.25rem;
  }

  img {
    margin-bottom: 1.25rem;
  }

  a {
    color: black;
  }
}

.discover {
  background: #d8eb59;
  padding-top: 3.375rem;
  padding-bottom: 3.375rem;

  > div > div > div {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  p {
    font-size: 1.3125rem;
    font-weight: 600;
  }
}

.button {
  composes: btn from global;
  display: inline-block;
  width: 55%;
}

@media only screen and (max-width: 768px) {
  .header {
    background-position: 79% 50%;
  }

  .intro,
  .sequences,
  .curriculum .discover {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .intro {
    p {
      margin-bottom: 3rem;
    }

    ul {
      margin-bottom: 2.25rem;
    }

    img {
      margin-bottom: 1rem;
    }
  }

  .intro li,
  .curriculum li {
    margin-bottom: 3.5rem;
  }

  .intro li:last-child,
  .curriculum li:last-child {
    margin-bottom: 0;
  }

  .sequences h2,
  .curriculum h2 {
    font-size: 1.75rem;
  }

  .sequences h2 {
    margin-bottom: 1rem;
  }

  .curriculum img {
    margin-bottom: 1.25rem;
  }

  .discover {
    text-align: center;

    > div > div > div {
      flex-direction: column;
    }

    p {
      margin-bottom: 1.875rem;
    }
  }
}
