@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  border: 1px solid $grey-200;
  border-radius: 0.25rem;
  color: black;
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  overflow: hidden;
}

.wrapper:hover {
  border-color: $grey-600;
  color: black;
  text-decoration: none;
}

.image {
  height: 8rem;
  position: relative;
}

.image > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.image > div {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  display: flex;
  font-size: 5rem;
  font-weight: 600;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.title {
  border-bottom: 1px solid $grey-200;
  color: $grey-800;
  font-size: 1.3125rem;
  font-weight: bold;
  height: 7.625rem;
  padding: 0.5625rem 0.875rem;
}

.classes {
  align-items: center;
  display: flex;
  font-size: 0.9375rem;
  font-weight: 600;
  padding: 0.9375rem;
}

.classes > img {
  color: $grey-600;
  margin-right: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .wrapper {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .image {
    height: 5rem;
  }

  .image > div {
    font-size: 3.5rem;
  }

  .title {
    font-size: 0.9375rem;
    height: 5.625rem;
  }

  .classes {
    padding: 0.75rem 0.875rem;
  }

  .classes > img {
    font-size: 1rem;
    width: 15px;
    height: 15px;
  }
}
