@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  align-items: center;
  background-color: $grey-100;
  display: flex;
  flex-direction: column;
  flex-grow: 10;
  font-size: rem(13);
  justify-content: center;
  line-height: rem(18);
  padding: rem(48) rem(16);
  text-align: center;

  img {
    margin-bottom: rem(43);
  }

  h3 {
    font-size: rem(15);
    font-weight: 600;
    line-height: rem(18);
    margin-bottom: rem(10);
  }

  p {
    color: $grey-600;
    margin: 0;
    margin-bottom: rem(18);
  }
}
