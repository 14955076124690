.title{
    margin-top: 5%;
    margin-bottom: 3%;
}

.projects{
    margin-bottom: 5%;
}

.wrapper {
    background-color: #EDEDED;
}