@import "~variables.scss";
@import "~tools.scss";

.mask {
  border-left: 1px solid transparent;
  margin: rem(16) 0 0 rem(-15);
  overflow: hidden;
  position: absolute;
  width: 0;
  left: 100%;
  top: 0;
  z-index: 90;
  transition: left 0.3s ease-in-out, width 0.3s ease-in-out,
    z-index 0.3s ease-in-out;

  &.isExpanded {
    border-left-color: $grey-200;
    width: 100%;
    z-index: 100;
  }

  &.isLeftAligned {
    border-left: 0;
    border-right: 1px solid transparent;
    left: 0;
    margin-left: 15px;
    z-index: 80;
  }

  &.isExpanded.isLeftAligned {
    border-left-color: transparent;
    border-right-color: $grey-200;
    left: -100%;
  }
}

.wrapper {
  background: white;
  border-radius: 0.25rem;
  color: $grey-600;
  font-size: 0.9375rem;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  text-align: left;
  width: 100%;

  ul {
    margin-bottom: 1.4375rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.scroll {
  border-top: 1px solid $grey-200;
  flex-shrink: 10;
  padding: 0.875rem;
  width: 100%;
}

.title {
  color: $grey-800;
  margin-bottom: 0.8125rem;

  > button {
    color: $grey-200;
    margin-left: 0.25rem;
  }
}

.access {
  border-top: 1px solid $grey-200;
  display: block;
  font-weight: bold;
  height: rem(53);
  line-height: 3.2875rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  a {
    color: black;
  }
}

.objectives {
  display: flex;
  flex-wrap: wrap;
}

@include mq("tablet-portrait") {
  .wrapper {
    position: absolute;
    left: 0;
    top: 0;
  }

  .scroll {
    border-top: 0;
    overflow-y: scroll;
  }
}
