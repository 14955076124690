@import "~variables.scss";
@import "~tools.scss";


.errorMessage{
  color: red;
}

.blockDescription {
  composes: quill-text from global;
  font-size: rem(16);
  line-height: rem(24);
  margin-bottom: rem(48);
}

@include mq("tablet-landscape") {
  .blockDescription {
    font-size: rem(18);
    line-height: rem(24);
  }
}

.anonimo {
  margin-top: 3%;
  margin-bottom: 3%;
}

.finish {
  margin-top: 3%;
  margin-bottom: 3%;
}

.surveySelects {
  margin-top: 3%;
  margin-bottom: 3%;
}

.header {
  text-align: center;
}

.description {
  text-align: center;
}

.submitForm {
  text-align: center
}

.anonymous {
  margin-right: 2%;
  margin-left: 2%;
}

.finished {
  margin-right: 2%;
  margin-left: 2%;
}

.globalSlider {
  width: 50%;
  margin: 50px 0 40px;
}

.globalComment {
  h3 {
    margin-bottom: 20px;
  }

  textarea {
    padding: 10px;
    border: 1px solid #CCC;
    border-radius: 5px;

    &:focus {
      border-color: #000;
    }
  }
}