@import "~variables.scss";
@import "~tools.scss";

.header {
  composes: container from global;
  font-size: 1.125rem;
  padding-top: 1.4375rem;
  padding-bottom: 1.25rem;
}

.image {
  align-items: center;
  background-image: url(./basic.jpg);
  background-position: 50% 50%;
  background-size: cover;
  color: white;
  display: flex;
  justify-content: center;
  margin-bottom: 1.875rem;
  padding: 1rem;
  text-align: center;
  width: 100%;
  height: 26rem;

  > h1 {
    font-size: 2.5rem;
    line-height: 1;
    max-width: 50%;
  }
}

.slogan {
  composes: container from global;
  font-size: 1.3125rem;
  font-weight: 600;
  padding-top: 5rem;
  padding-bottom: 5rem;
  text-align: center;

  p {
    margin-bottom: 0;
  }
}

.list {
  display: flex;
  flex-flow: row nowrap;
  list-style: none;
  justify-content: space-around;
  margin-bottom: 0;

  > li {
    padding: 5.5rem 2.8rem;
    text-align: center;
    flex-grow: 1;
    flex-basis: 33%;

    > a {
      > img {
        margin-bottom: 1.3125rem;
      }

      > h2 {
        font-size: 1.3125rem;
        margin-bottom: 0.75rem;
        color: black;
      }
    }

    > p {
      margin-bottom: 1.5rem;
    }
  }
}

li.sustainableDevGoals > p {
  color: $grey-600;
}

li.knowledgeMatrix {
  background: $grey-700;
  color: white;

  > a,
  > a > h2 {
    color: white;
  }
}

li.learningObjectives {
  background: #0bdb9f;
}

.doubleLineHeight {
  line-height: 2rem;
}

.btn {
  composes: btn from global;

  .knowledgeMatrix & {
    border-color: rgba(255, 255, 255, 0.2);
  }
}

@media only screen and (max-width: 768px) {
  .header {
    font-size: 1rem;
    padding-bottom: 0;
  }

  .image {
    margin-bottom: 1.25rem;

    > h1 {
      font-size: 2.125rem;
      max-width: none;
    }
  }

  .slogan {
    font-size: 1.125rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .list {
    flex-direction: column;

    > li {
      padding: 2.75rem 1rem;
      width: 100%;

      > p {
        margin-bottom: 1rem;
      }
    }
  }
}
