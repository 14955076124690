@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  align-items: flex-start;
  border-top: 1px solid $grey-200;
  color: $grey-600;
  cursor: pointer;
  display: flex;
  font-size: 0.9375rem;
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;
  text-align: left;

  &:first-child:not(.hasBorder) {
    border-top: 0;
  }

  &.hasBorder {
    margin-bottom: 0.875rem;

    &:last-child {
      border-bottom: 1px solid $grey-200;
    }
  }

  div:first-child {
    margin-bottom: 0;
  }
}

.description {
  flex-grow: 10;
  margin: 0.5rem 1.375rem 0 0.625rem;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: height 0.3s ease-in-out;
  white-space: nowrap;

  .isExpanded & {
    white-space: normal;
  }
}

.related {
  color: $grey-600;
  display: block;
  font-size: 1.125rem;
  font-weight: 600;
  margin-top: 0.8125rem;

  > img {
    margin-left: 0.5rem;
  }
}

.toggler {
  margin-top: rem(12);
  opacity: 0.7;
  width: rem(14);
}

@media only print {
  .description {
    height: auto !important;
    white-space: normal;
  }
}
