@import "~variables.scss";
@import "~tools.scss";

.ql-image {
  max-width: 80%;
}

textarea {
  width: 50%;
  height: 60px;
}

.comment {
  margin-top: 3%;
  margin-bottom: 3%;
}

.rating {
  margin-top: 3%;
  margin-bottom: 3%;
}

i {
  font-size: 30px;
}

.contentArea {
  margin-top: 3%;
  margin-bottom: 3%;
}
