@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  align-items: center;
  background-color: white;
  color: $grey-600;
  display: flex;
  font-size: rem(13);
  line-height: rem(18);
  justify-content: space-between;
  margin-bottom: rem(10);
  padding: rem(10) rem(14);

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.info {
  > h4 {
    color: black;
    font-size: rem(18);
    line-height: rem(24);
    margin: 0;
  }

  > p {
    margin: 0;
  }
}

.years {
  display: flex;
}

@include mq("tablet-portrait") {
  .wrapper {
    height: rem(150);
    margin-bottom: rem(30);
    padding: rem(17) rem(12);
    position: relative;
  }

  .info {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;

    > p {
      align-items: center;
      display: flex;
      height: rem(34);
      margin-right: rem(72);
    }
  }

  .years {
    right: rem(12);
    bottom: rem(17);
    position: absolute;
  }
}
