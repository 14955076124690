@import "~variables.scss";
@import "~tools.scss";

.wrapper {
}

.header,
.example,
.objectives {
  composes: container from global;
}

.header {
  font-size: 1.125rem;
  padding-top: 2.5rem;
  padding-bottom: 0.75rem;
}

.header h1 {
  font-size: 2.125rem;
  margin-bottom: 0.875rem;
  text-align: center;
}

.example {
  font-size: 1.125rem;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
  text-align: center;
}

.example h2 {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 1.625rem;
  text-align: center;
}

.example p {
  text-align: left;
}

.code {
  display: flex;
  font-size: 2.125rem;
  justify-content: center;
  margin-bottom: 3.75rem;
}

.code > span {
  border: 1px solid $grey-200;
  border-radius: 0.25rem;
  margin: 0 0.1875rem;
  padding: 1.25rem 1.5rem;
}

.code > span.code1 {
  background: black;
  border-color: black;
  color: white;
}

.code > span.code2 {
  border-color: black;
}

.code > span.code3 {
  background: $color-cerulean;
  border-color: $color-cerulean;
  color: white;
}

.code > span.code4 {
  border-color: $color-cerulean;
  color: $color-cerulean;
}

.btnObjectives {
  composes: btn from global;
  display: none;
}

.objectives {
  min-height: 30rem;
  padding-top: 1.875rem;
  padding-bottom: 2.5rem;
  position: relative;
  text-align: center;
}

.objectivesTitle2 {
  display: none;
}

.objectives h2 {
  font-size: 1.875rem;
  margin-bottom: 1.625rem;
}

.objectives h3 {
  font-size: 1.3125rem;
  margin-bottom: 0.8125rem;
}

.objectives h4 {
  font-size: 1.125rem;
  font-weight: 600;
}

.pickYear,
.pickCurricularComponent {
  margin-bottom: 1.875rem;
  text-align: left;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.warning {
  align-items: flex-start;
  color: $grey-600;
  display: flex;
  font-size: 0.8125rem;
  margin-top: 1.5rem;
}

.warning > img {
  color: $grey-200;
  margin-right: 0.3125rem;
}

.next {
  composes: btn from global;
  display: inline-block;
}

.loading {
  align-items: center;
  display: none;
  justify-content: center;
  min-height: 20rem;
}

.results {
  display: none;
  font-size: 1.125rem;
  position: relative;
}

.back {
  color: $grey-600;
  font-size: 1.125rem;
  font-weight: 600;
  position: absolute;
  left: 15px;
  top: 0;
}

.back > img {
  margin-right: 0.5rem;
}

.close {
  display: none;
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
}

@media only screen and (max-width: 768px) {
  .code {
    font-size: 1.25rem;
  }

  .code > span {
    padding: 0.25rem 0.5rem;
  }

  .btnObjectives {
    display: inline-block;
  }

  .objectives,
  .results {
    background: white;
    max-width: none !important;
    overflow-y: auto;
    padding: 1rem;
    position: fixed;
    width: 100%;
    left: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    transition: left 0.3s ease-in-out !important;
  }

  .objectives {
    z-index: 110;
  }

  .objectivesTitle1 {
    display: none;
  }

  .objectivesTitle2 {
    display: block;
  }

  .objectives h2,
  .results h2 {
    font-size: 1.75rem;
  }

  .results {
    height: auto;
    opacity: 1;
    padding: 1rem;
    z-index: 90;
  }

  .isVisible {
    left: 0;
  }

  .next {
    margin-bottom: 1rem;
  }

  .back {
    display: none;
  }

  .close {
    display: block;
  }
}
