@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  border-radius: 0.25rem;
  color: white;
  display: flex;
  font-weight: 600;
  margin-bottom: 1.875rem;
  padding-left: 2.125rem;
  padding-right: 2.5rem;
  padding-top: 0.4375rem;
  position: relative;
  height: 10rem;
}

.wrapper:hover {
  color: white;
  text-decoration: none;
}

.number {
  font-size: 5rem;
}

.label {
  font-size: 1.125rem;
  margin-left: 1rem;
  margin-top: 1.5rem;
}

.icon {
  height: 3.125rem;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

@media only screen and (max-width: 768px) {
  .wrapper {
    margin-bottom: 1rem;
  }
}
