@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  align-items: center;
  color: $grey-600;
  display: flex;
  font-size: rem(13);
  justify-content: flex-start;
  line-height: rem(18);
  padding-top: rem(5);
  padding-bottom: rem(5);
  text-align: left;
}

.info {
  margin-left: rem(16);

  > h4 {
    color: black;
    font-size: rem(15);
    font-weight: 600;
    line-height: inherit;
    margin-bottom: rem(4);
  }

  > p {
    margin: 0;
  }
}
