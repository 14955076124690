@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  background: white;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 100%;
  top: 0;
  bottom: 0;
  transition: left 0.3s ease-in-out;
  z-index: 120;

  &.isExpanded {
    left: 0;
  }
}
