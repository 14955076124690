@import "~variables.scss";
@import "~tools.scss";

.header {
  composes: fullModalHeader from global;
  color: white;
  font-size: 1.125rem;
  text-align: center;
}

.header h1 {
  font-size: 2.125rem;
  margin-bottom: 1.375rem;
}

.header h2 {
  font-size: 1.3125rem;
  font-weight: 600;
  margin-bottom: 1.8125rem;
}

.header img {
  margin-bottom: 1.5rem;
  height: 5.625rem;
}

.header p {
  line-height: 1.5rem;
  margin: 0 auto 2.5rem auto;
  text-align: left;
}

.number {
  border: 3px solid white;
  border-radius: 5rem;
  font-size: 2.125rem;
  font-weight: 600;
  line-height: 4.5rem;
  margin: 5rem auto 1.75rem auto;
  text-align: center;
  width: 5rem;
  height: 5rem;
}

.contents {
  composes: container from global;
  composes: fullModalContents from global;
  text-align: center;
}

.contents h2 {
  color: $grey-600;
  font-size: 1.875rem;
  margin-top: 2.125rem;
  margin-bottom: 1.5rem;
}

.list {
  margin-bottom: 0;
  text-align: left;
}

.button {
  composes: btn from global;
  display: inline-block;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.close {
  position: absolute;
  right: 1.75rem;
  top: 1.75rem;
}

@media only screen and (max-width: 768px) {
  .header {
    font-size: 1rem;
  }

  .number {
    margin-top: 2.8125rem;
  }

  .content h2 {
    font-size: 1.3125rem;
    margin-top: 1.875rem;
    margin-bottom: 1rem;
  }

  .button {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }

  .close {
    right: 1.1875rem;
    top: 1.1875rem;
  }
}
