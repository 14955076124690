@import "~variables.scss";
@import "~tools.scss";

:global(.sticky-inner-wrapper) {
  z-index: 100;

  :global(.active) & {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  }
}

.wrapper {
  background: white;

  :global(.headroom) & {
    top: 0;
    left: 0;
    right: 0;
  }

  :global(.headroom--unfixed) & {
    position: relative;
    transform: translateY(0);
  }

  :global(.headroom--scrolled) & {
    background-color: white;
    border-bottom: 1px solid $grey-200;
    z-index: 110;
    transition: transform 200ms ease-in-out;
  }

  :global(.headroom--unpinned) & {
    position: fixed;
    transform: translateY(0);
  }

  :global(.headroom--pinned) & {
    position: fixed !important;
    transform: translateY(72px);
  }

  @include mq("tablet-landscape") {
    border-bottom: 1px solid $grey-200;
  }
}

.container {
  composes: container from global;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding-top: rem(15);
  padding-bottom: rem(15);

  @include mq("tablet-landscape") {
    padding-top: rem(8);
    padding-bottom: rem(8);
  }
}

.info,
.info1 {
  padding: rem(10);
  padding-left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 80px - 48px);

  p {
    color: $grey-600;
    font-size: rem(11);
    margin: 0;
  }

  h1 {
    display: inline-block;
    font-size: rem(16);
    margin: 0;
  }

  a {
    color: black;
    text-decoration: none;
  }

  @include mq("tablet-landscape") {
    width: auto;

    p {
      font-size: rem(13);
      margin-bottom: rem(2);
    }

    h1 {
      font-size: rem(21);
    }
  }
}

.info1 {
  width: calc(100% - 80px);
}

.image {
  background-color: $grey-300;
  margin-right: rem(10);
  object-fit: cover;
  width: rem(60);
  height: rem(42);
}

.btnSave1 {
  align-items: center;
  color: $grey-600;
  display: flex;
  flex-direction: column;
  font-size: rem(12);
  justify-content: center;
  margin-left: auto;
  min-width: rem(36);

  > img {
    margin-bottom: rem(4);
  }

  @include mq("tablet-landscape") {
    display: none;
  }
}

.btnSave2 {
  composes: btn from global;
  display: none;

  @include mq("tablet-landscape") {
    display: block;
    margin-left: auto;
    margin-right: rem(-15);
  }
}

@media only print {
  .wrapper {
    position: static !important;
  }
}
