.wrapper {
  transform-origin: 50% 50%;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
