@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  align-items: center;
  border: 1px solid $grey-200;
  border-radius: 0.25rem;
  color: $grey-600;
  display: flex;
  font-weight: 600;
  min-height: 5rem;
  margin-bottom: 1.875rem;
  padding: 1.25rem 2rem 1.25rem 1rem;
}

.wrapper:hover {
  border-color: $grey-600;
  color: $grey-600;
  text-decoration: none;
}

.wrapper:hover .label {
  text-decoration: underline;
}

.number {
  border: 1px solid $grey-200;
  border-radius: 3rem;
  font-size: 1.125rem;
  line-height: 2.25rem;
  margin-right: 0.625rem;
  text-align: center;
  min-width: 2.5rem;
  height: 2.5rem;
}

.label {
  font-size: 1.0625rem;
}

@media only screen and (max-width: 768px) {
  .wrapper {
    margin-bottom: 0.5625rem;
  }
}
