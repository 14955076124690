@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  align-items: center;
  background-color: white;
  border-bottom: 1px solid $grey-200;
  color: $grey-600;
  display: flex;
  font-size: rem(13);
  line-height: rem(18);
  justify-content: space-between;
  margin-bottom: rem(10);
  padding: rem(10) rem(14);
  width: 100%;

  &:last-child {
    border-bottom: 0;
  }
}

.title {
  color: black;
  font-size: rem(18);
  line-height: rem(24);
  margin: 0;
}

.years {
  display: flex;
}
