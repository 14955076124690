@import "~variables.scss";
@import "~tools.scss";

.modal {
  background: white;
  height: 100%;
}

@include mq("tablet-portrait") {
  .modal {
    border-radius: rem(4);
    display: flex;
    flex-direction: column;
    height: auto;
    max-height: 80vh;
    min-width: rem(730);
    overflow: hidden;

    &.isFixed {
      width: rem(730);
    }

    &.isSmall {
      min-width: auto;
      width: rem(540);
    }
  }
}
