@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  margin-bottom: rem(30);
}

.title {
  display: flex;
  margin-bottom: rem(14);

  > h4 {
    font-weight: normal;
    line-height: inherit;
    margin: 0;
  }
}

.number {
  align-items: center;
  border: 1px solid $grey-200;
  border-radius: 50%;
  color: $grey-600;
  display: flex;
  flex-shrink: 0;
  font-size: rem(16);
  justify-content: center;
  margin-right: rem(14);
  width: rem(32);
  height: rem(32);
}
