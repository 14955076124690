@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  border: 1px solid $grey-200;
  border-radius: rem(4);
  margin-bottom: rem(24);

  > h4 {
    line-height: rem(22);
    margin: rem(21) rem(24);
  }
}

.table {
  overflow-x: auto;
}
