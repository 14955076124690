@import "~variables.scss";
@import "~tools.scss";

.row {
  display: flex;
  width: 100%;
}

.col1 {
  display: none;
  max-width: rem(256);
  padding: rem(15);

  @include mq("tablet-landscape") {
    border-right: 1px solid $grey-200;
    display: block;
  }
}

.col2 {
  display: flex;
  flex-direction: column;
  flex-grow: 10;
  // overflow-y: auto;
}

.small {
  @include mq("tablet-landscape") {
    display: none;
  }
}

.saved {
  composes: __react_component_tooltip from global;
  composes: type-dark from global;
  composes: tooltip from global;
  margin-left: rem(15);
  margin-right: rem(15);
  max-width: none;
  opacity: 1;
  padding: rem(20) rem(21);
  position: static;
  visibility: visible;

  @include mq("tablet-landscape") {
    margin-left: 0;
    margin-top: rem(8);
  }
}

.list {
  flex: 1 1 rem(380);
  overflow-y: auto;

  > p {
    color: $grey-600;
    font-size: rem(13);
    line-height: rem(16);
    margin: rem(15);
  }
}

.footer {
  background: white;
  border-top: 1px solid $grey-200;
  display: flex;
  justify-content: flex-start;
  padding: rem(16);
  position: relative;
}

.btn {
  color: $grey-600;
  font-size: rem(18);
  font-weight: 600;
  line-height: rem(22);

  > img {
    margin-right: rem(14);
  }
}
