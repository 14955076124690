@import "~variables.scss";
@import "~tools.scss";

.environment {
  align-items: center;
  color: $grey-600;
  display: flex;
  font-size: rem(15);
  font-weight: 600;
  line-height: rem(18);
  margin-bottom: rem(30);

  > img {
    margin-right: rem(10);
  }
}
