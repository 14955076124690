.wrapper {
  align-items: center;
  background: white;
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  visibility: hidden;
  z-index: 50;

  &.isVisible {
    visibility: visible;
  }
}
