@function rem($px) {
  @return #{$px / 16}rem;
}

@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);

    @if $type == max {
      $width: $width - 1px;
    }

    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

@mixin pill() {
  border-radius: rem(36);
  color: $grey-600;
  font-size: rem(12);
  font-weight: 600;
  margin-right: rem(6);
  margin-bottom: rem(6);
  padding: rem(3) rem(12);

  @include mq("tablet-landscape") {
    font-size: rem(15);
    margin-right: rem(8);
    margin-bottom: rem(8);
    padding: rem(6) rem(16);
  }
}
