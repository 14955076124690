@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  background: white;
  border-radius: rem(4);
  margin-bottom: rem(16);
  overflow: hidden;
  position: relative;
  text-align: left;
}

.image,
.initials {
  width: 100%;
  height: rem(160);
}

.image > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.initials {
  align-items: center;
  background: $grey-300;
  color: $grey-600;
  display: flex;
  font-size: rem(80);
  font-weight: 600;
  justify-content: center;
}

.date {
  color: $grey-600;
  font-size: 0.9375rem;
  margin: rem(16) rem(15) rem(10) rem(15);
}

.btnSave {
  position: absolute;
  right: rem(16);
  top: rem(180);
}

.title {
  color: $grey-800;
  font-size: rem(21);
  font-weight: bold;
  height: rem(50);
  line-height: 1.2;
  margin: 0 rem(15) rem(15) rem(15);
  overflow: hidden;

  a {
    color: $grey-800;
  }
}
