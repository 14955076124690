@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 10;
}

.h1 {
  font-size: rem(40);
  margin-bottom: rem(20);
  margin-top: rem(27);
}

.h2 {
  font-weight: normal;
  font-size: rem(18);
  line-height: rem(22);
  margin-top: rem(12);
  margin-bottom: rem(2);
}

.list {
  background-color: $grey-100;
}

.results {
  composes: container from global;
  padding-top: rem(16);
  padding-bottom: rem(16);
}

.results > ul > li {
  position: relative;
}

.center {
  margin-bottom: rem(24);
  text-align: center;
}

.load {
  composes: btn from global;
  margin: 0 auto;
}

.root {
  min-width: 275;
}

.coverImage {
  width: 100%;
  height: 170px;
}

.itemTitle {
  text-align: justify;
  color: #252525;
  font-size: 1.3125rem;
  font-weight: bold;
  height: 3.125rem;
  line-height: 1.2;
}

label {
  font-size: 0.8rem;
  text-align: justify;
}

img {
  max-width: 100%;
}

.expand {
  align-items: center;
  border-top: 1px solid $grey-200;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: rem(8);
}


@media only screen and (max-width: 768px) {
  .h1 {
    font-size: rem(34);
    margin-bottom: rem(12);
  }
}
