@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  padding: rem(15);
  padding-bottom: rem(72);
}

.contents {
  margin-left: auto;
  margin-right: auto;
  width: rem(350);
}

.center {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: rem(40);
}

.imageWrapper {
  margin-bottom: rem(8);
  position: relative;
}

.letter,
.image {
  border-radius: 50%;
  margin: rem(4);
  position: relative;
  width: rem(100);
  height: rem(100);
  z-index: 2;
}

.image {
  object-fit: cover;
}

.letter {
  align-items: center;
  background-color: $color-turquoise;
  color: white;
  display: flex;
  font-size: rem(34);
  font-weight: 600;
  justify-content: center;
}

.progress {
  background-color: $grey-200;
  border-radius: 50%;
  position: absolute;
  width: rem(108);
  height: rem(108);
  z-index: 1;
}

.actions {
  color: $grey-600;
  font-size: rem(15);
  font-weight: bold;
  text-transform: lowercase;

  > label,
  > button {
    background: none;
    border: 0;
    color: inherit;
    cursor: pointer;
    font-size: inherit;
    font-weight: inherit;
    padding: 0;
    text-decoration: underline;
    text-transform: inherit;
  }

  > span {
    margin: 0 rem(8);
  }
}

.file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.field + .field {
  margin-top: rem(40);
}

.obs {
  color: $grey-600;
  font-size: rem(13);
  line-height: rem(24);
  margin-top: rem(12);
  margin-bottom: rem(26);

  > a {
    color: inherit;
    text-decoration: underline;
  }
}
