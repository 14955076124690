@import "~variables.scss";
@import "~tools.scss";

.wrapper {
  background: $grey-200;
  font-size: 1.125rem;
  height: 100%;
  text-align: center;
}

.wrapper h1 {
  font-size: 2.125rem;
  margin-bottom: 1.9375rem;
}

.wrapper h2 {
  font-size: 1.3125rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.wrapper p {
  margin: 0 auto 1.75rem auto;
}

.header {
  composes: fullModalHeader from global;
}

.contents {
  composes: fullModalContents from global;
}

.number {
  border: 3px solid black;
  border-radius: 5rem;
  font-size: 2.125rem;
  font-weight: 600;
  line-height: 4.5rem;
  margin: 5rem auto 1.75rem auto;
  width: 5rem;
  height: 5rem;
}

.button {
  composes: btn from global;
  display: inline-block;
  margin-top: 2.625rem;
  margin-bottom: 2.625rem;
}

.close {
  position: absolute;
  right: 1.75rem;
  top: 1.75rem;
}

@media only screen and (max-width: 768px) {
  .wrapper {
    font-size: 1rem;
  }

  .number {
    margin-top: 2.8125rem;
  }

  .button {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }

  .close {
    right: 1.1875rem;
    top: 1.1875rem;
  }
}
